<!-- render desktop view if enough space -->
<ng-container *ngIf="service.expandedSidebar">
  <ng-container *ngTemplateOutlet="sidebarExpanded"></ng-container>
</ng-container>
<ng-container *ngIf="!service.expandedSidebar">
  <ng-container *ngTemplateOutlet="sidebarCollapsed"></ng-container>
</ng-container>

<!-- render mobile view if not enough space -->
<ng-container *ngTemplateOutlet="mobile"></ng-container>

<!-- desktop -->
<ng-template #sidebarCollapsed>
  <nav
    class="
      sm:portrait:flex
      md:landscape:flex
      isolate
      flex-col
      items-center
      gap-8
      font-semibold
      bg-steel-blue-800
      p-3
      shrink-0
      h-full
      relative
      w-16
      z-40
      select-none
      hidden
    "
  >
    <ng-container *ngIf="!showSettings">
      <!-- brand -->
      <ng-container *ngTemplateOutlet="brand"></ng-container>

      <!-- notifications -->
      <ng-container *ngTemplateOutlet="notifications"></ng-container>

      <!-- nav items -->
      <div
        class="
          flex
          grow
          overflow-x-visible overflow-y-auto
          scrollbar-hidden
          h-full
        "
      >
        <ul
          class="flex flex-col gap-3 w-full text-white items-center hover:w-96"
        >
          <ng-container *ngFor="let nav of mainNavigation">
            <ng-container *ngIf="nav?.items">
              <ng-container *agHasPermissionAny="nav.permissions">
                <li class="relative">
                  <!-- toggle trigger -->
                  <ag1-menu
                    (menuClosed)="service.collapseLists()"
                    [isOpen]="service.selectedList === nav.label"
                  >
                    <ng-template #customTrigger>
                      <button
                        [ag1Tooltip]="nav.label"
                        tooltipPosition="right"
                        class="
                          font-semibold
                          text-white/80
                          hover:text-white
                          w-full
                          p-1
                          rounded
                          relative
                          cursor-pointer
                          outline-none
                          whitespace-nowrap
                          select-none
                          focus-visible:ring
                          focus-visible:ring-offset-white
                          focus-visible:ring-offset-2
                          focus-visible:ring-steel-blue-500
                        "
                        [ngClass]="[
                          unsavedNewOrder &&
                          nav?.label === 'Orders' &&
                          !service.expandedList(nav.label)
                            ? 'after:block after:h-2 after:w-2 after:rounded-full after:bg-french-blue-500 after:top-1 after:left-1 after:absolute after:animate-ping'
                            : '',
                          service.expandedList(nav.label) ? 'bg-white/10' : ''
                        ]"
                        [ag1Tooltip]="nav.label"
                        tooltipPosition="right"
                        (click)="service.expandList(nav.label)"
                      >
                        <!-- icon -->
                        <svg-icon *ngIf="nav?.icon" [src]="nav.icon"></svg-icon>
                      </button>
                    </ng-template>
                    <!-- nested items -->
                    <ng-template #customOverlay>
                      <ul
                        *ngIf="service.expandedList(nav.label)"
                        class="
                          ml-8
                          my-2
                          flex flex-col
                          absolute
                          left-5
                          -top-5
                          bg-steel-blue-800
                          whitespace-nowrap
                          p-3
                          rounded
                          gap-2
                        "
                      >
                        <li class="p-1 text-white font-semibold">
                          {{ nav.label }}
                        </li>

                        <ng-container *ngFor="let navItem of nav?.items">
                          <ng-container *agHasPermission="navItem.permission">
                            <li>
                              <a
                                class="
                                  font-semibold
                                  flex
                                  items-center
                                  gap-3
                                  text-white/80
                                  hover:text-white
                                  rounded
                                  p-1
                                  cursor-pointer
                                  outline-none
                                  whitespace-nowrap
                                  select-none
                                  focus-visible:ring
                                  focus-visible:ring-offset-white
                                  focus-visible:ring-offset-2
                                  focus-visible:ring-steel-blue-500
                                "
                                [routerLinkActive]="'bg-white/10'"
                                [ngClass]="[
                                  unsavedNewOrder &&
                                  navItem?.label === 'New Order'
                                    ? 'after:block after:h-2 after:w-2 after:rounded-full after:bg-french-blue-500 after:top-1 after:left-1 after:absolute after:animate-ping relative'
                                    : ''
                                ]"
                                [routerLink]="navItem?.route"
                                (click)="
                                  service.activateLink(navItem?.label);
                                  service.collapseLists()
                                "
                              >
                                <ng-container *ngIf="navItem?.new">
                                  <ng-container
                                    [ngTemplateOutlet]="newBadge"
                                  ></ng-container>
                                </ng-container>

                                <svg-icon
                                  *ngIf="navItem?.icon"
                                  [src]="navItem.icon"
                                ></svg-icon>

                                <span>{{ navItem?.label }}</span>
                              </a>
                            </li>
                          </ng-container>
                        </ng-container>
                      </ul>
                    </ng-template>
                  </ag1-menu>
                </li>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!nav.items">
              <ng-container *agHasPermission="nav.permission">
                <li>
                  <a
                    [ag1Tooltip]="nav.label"
                    tooltipPosition="right"
                    [routerLinkActive]="'bg-white/10'"
                    class="
                      text-white/80
                      hover:text-white
                      w-full
                      p-1
                      rounded
                      block
                      cursor-pointer
                      outline-none
                      whitespace-nowrap
                      select-none
                      focus-visible:ring
                      focus-visible:ring-offset-white
                      focus-visible:ring-offset-2
                      focus-visible:ring-steel-blue-500
                    "
                    [routerLink]="nav?.route"
                    (click)="
                      service.activateLink(nav?.label); service.collapseLists()
                    "
                  >
                    <!-- icon -->
                    <svg-icon [src]="nav.icon"></svg-icon>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
        <!-- a line to break the [ag1Tooltip] hover -->
        <div class="absolute right-0 top-0 p-2 h-full"></div>
      </div>

      <!-- settings/ profile -->
      <ul class="flex flex-col gap-3 w-full text-white mt-auto items-center">
        <ng-container *ngFor="let nav of fixedNavigation">
          <ng-container *agHasPermission="nav.permission">
            <li>
              <a
                [ag1Tooltip]="nav.label"
                tooltipPosition="right"
                [routerLinkActive]="'bg-white/10'"
                class="
                  text-white/80
                  hover:text-white
                  flex
                  items-center
                  gap-3
                  w-full
                  grow
                  p-1
                  rounded
                  cursor-pointer
                  outline-none
                  whitespace-nowrap
                  select-none
                  focus-visible:ring
                  focus-visible:ring-offset-white
                  focus-visible:ring-offset-2
                  focus-visible:ring-steel-blue-500
                "
                [routerLink]="nav?.route"
                (click)="setMainPageRoute(); showSettings = !showSettings"
              >
                <!-- icon -->
                <svg-icon [src]="nav.icon"></svg-icon>
              </a>
            </li>
          </ng-container>
        </ng-container>
        <ng-template *ngTemplateOutlet="logoutCollapsed"></ng-template></ul
    ></ng-container>
    <ng-container *ngIf="showSettings">
      <!-- navigate back -->
      <ul class="flex flex-col w-full text-white">
        <li>
          <button
            tooltipPosition="right"
            [ag1Tooltip]="'Back to ' + navigationRouteHumanized()"
            class="
              text-white/80
              hover:text-white
              flex
              items-center
              gap-3
              w-full
              grow
              py-2
              px-3
              rounded
              cursor-pointer
              outline-none
              whitespace-nowrap
              select-none
              focus-visible:ring
              focus-visible:ring-offset-white
              focus-visible:ring-offset-2
              focus-visible:ring-steel-blue-500
            "
            [routerLink]="nav?.route"
            (click)="backToPageNavigation(); showSettings = !showSettings"
          >
            <svg-icon src="assets/icons/general/arrow-left.svg"></svg-icon>
          </button>
        </li>
        <li>
          <hr class="m-0 opacity-25 h-px" />
        </li>
      </ul>
      <!-- nav items -->
      <div
        class="
          flex
          grow
          overflow-x-visible overflow-y-auto
          scrollbar-hidden
          h-full
        "
      >
        <ul
          class="flex flex-col gap-3 w-full text-white items-center hover:w-96"
        >
          <ng-container *ngFor="let nav of settingsNavigation">
            <ng-container *agHasPermission="nav.permission">
              <li class="">
                <a
                  [ag1Tooltip]="nav.label"
                  tooltipPosition="right"
                  [routerLinkActive]="'bg-white/10'"
                  class="
                    text-white/80
                    hover:text-white
                    w-full
                    p-1
                    rounded
                    block
                    cursor-pointer
                    outline-none
                    whitespace-nowrap
                    select-none
                    focus-visible:ring
                    focus-visible:ring-offset-white
                    focus-visible:ring-offset-2
                    focus-visible:ring-steel-blue-500
                  "
                  [routerLink]="nav?.route"
                  (click)="
                    service.activateLink(nav?.label); navigationRouteHumanized()
                  "
                >
                  <!-- icon -->
                  <svg-icon [src]="nav.icon"></svg-icon>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </ul>
        <!-- a line to break the [ag1Tooltip] hover -->
        <div class="absolute right-0 top-0 p-2 h-full"></div>
      </div>
      <!-- logout -->
      <ul class="flex flex-col gap-3 w-full text-white mt-auto">
        <ng-container *ngTemplateOutlet="logoutCollapsed"></ng-container>
      </ul>
    </ng-container>
    <!-- sidebar toggle -->
    <ng-container *ngTemplateOutlet="sidebarToggle"></ng-container>
  </nav>
</ng-template>

<ng-template #sidebarExpanded>
  <nav
    class="
      sm:portrait:flex
      md:landscape:flex
      isolate
      flex-col
      items-start
      gap-4
      font-semibold
      bg-steel-blue-800
      p-3
      shrink-0
      h-full
      relative
      w-64
      z-40
      select-none
      hidden
    "
  >
    <!-- sidebar toggle -->
    <ng-container *ngTemplateOutlet="sidebarToggle"></ng-container>

    <ng-container *ngIf="!showSettings">
      <div class="flex items-center justify-between gap-1 w-full">
        <!-- brand -->
        <ng-container *ngTemplateOutlet="brand"></ng-container>

        <!-- notifications -->
        <ng-container *ngTemplateOutlet="notifications"></ng-container>
      </div>

      <!-- week indicator -->
      <div
        class="
          text-white
          border-white/10
          rounded
          p-3
          w-full
          flex
          items-center
          gap-3
          select-text
          border
          mb-3
        "
      >
        <svg-icon src="assets/icons/general/calendar.svg"></svg-icon>

        <span class="font-semibold">{{ isoWeekService.currentWeek }}</span>
      </div>

      <!-- nav items -->
      <ul
        class="flex flex-col grow gap-3 overflow-auto w-full text-white p-0.5"
      >
        <ng-container *ngFor="let nav of mainNavigation">
          <ng-container *ngIf="nav?.items">
            <ng-container *agHasPermissionAny="nav.permissions">
              <li>
                <!-- toggle trigger -->
                <button
                  class="
                    font-semibold
                    text-white/80
                    hover:text-white
                    flex
                    items-center
                    gap-3
                    w-full
                    grow
                    px-3
                    py-2
                    rounded
                    relative
                    cursor-pointer
                    outline-none
                    whitespace-nowrap
                    select-none
                    focus-visible:ring
                    focus-visible:ring-offset-white
                    focus-visible:ring-offset-2
                    focus-visible:ring-steel-blue-500
                  "
                  [ngClass]="
                    unsavedNewOrder &&
                    nav?.label === 'Orders' &&
                    !service.expandedList(nav.label)
                      ? 'after:block after:h-2 after:w-2 after:rounded-full after:bg-french-blue-500 after:top-1 after:left-1 after:absolute after:animate-ping'
                      : ''
                  "
                  (click)="service.expandList(nav.label)"
                >
                  <!-- icon + label -->
                  <div class="flex items-center gap-3 mr-auto">
                    <svg-icon *ngIf="nav?.icon" [src]="nav.icon"></svg-icon>
                    <span
                      class="truncate w-[8.75rem] text-left"
                      [ag1Tooltip]="nav?.label"
                      [tooltipPosition]="'right'"
                      >{{ nav?.label }}</span
                    >
                  </div>

                  <!-- toggle icon -->
                  <ng-container *ngIf="service.expandedList(nav?.label)">
                    <svg-icon src="assets/icons/general/chevron-down.svg">
                    </svg-icon>
                  </ng-container>
                  <ng-container *ngIf="!service.expandedList(nav?.label)">
                    <svg-icon src="assets/icons/general/chevron-right.svg">
                    </svg-icon>
                  </ng-container>
                </button>

                <!-- nested items -->
                <ul
                  *ngIf="service.expandedList(nav.label)"
                  class="ml-8 my-2 flex flex-col"
                >
                  <ng-container *ngFor="let navItem of nav?.items">
                    <ng-container *agHasPermission="navItem.permission">
                      <li>
                        <a
                          class="
                            text-white/80
                            hover:text-white
                            flex
                            items-center
                            gap-3
                            rounded
                            px-3
                            py-2
                            relative
                            before:block
                            before:w-px
                            before:bg-white/20
                            before:-left-2.5
                            before:top-0
                            before:bottom-0
                            before:absolute
                            cursor-pointer
                            outline-none
                            whitespace-nowrap
                            select-none
                            focus-visible:ring
                            focus-visible:ring-offset-white
                            focus-visible:ring-offset-2
                            focus-visible:ring-steel-blue-500
                          "
                          [routerLinkActive]="'bg-white/10'"
                          [ngClass]="[
                            unsavedNewOrder && navItem?.label === 'New Order'
                              ? 'after:block after:h-2 after:w-2 after:rounded-full after:bg-french-blue-500 after:top-1 after:left-1 after:absolute after:animate-ping'
                              : ''
                          ]"
                          [routerLink]="navItem?.route"
                          (click)="service.activateLink(navItem?.label)"
                        >
                          <ng-container *ngIf="navItem?.new">
                            <ng-container
                              [ngTemplateOutlet]="newBadge"
                            ></ng-container>
                          </ng-container>

                          <svg-icon
                            *ngIf="navItem?.icon"
                            [src]="navItem.icon"
                          ></svg-icon>
                          <span>{{ navItem?.label }}</span>
                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!nav.items">
            <ng-container *agHasPermission="nav.permission">
              <li>
                <a
                  [routerLinkActive]="'bg-white/10'"
                  class="
                    text-white/80
                    hover:text-white
                    flex
                    items-center
                    gap-3
                    w-full
                    grow
                    py-2
                    px-3
                    rounded
                    cursor-pointer
                    outline-none
                    whitespace-nowrap
                    select-none
                    focus-visible:ring
                    focus-visible:ring-offset-white
                    focus-visible:ring-offset-2
                    focus-visible:ring-steel-blue-500
                  "
                  [routerLink]="nav?.route"
                  (click)="
                    service.activateLink(nav?.label); service.collapseLists()
                  "
                >
                  <!-- icon + label -->
                  <div class="flex items-center gap-3">
                    <svg-icon [src]="nav.icon"></svg-icon>
                    <span>{{ nav?.label }}</span>
                  </div>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>

      <!-- settings/ profile -->
      <ul class="flex flex-col gap-3 w-full text-white mt-auto">
        <ng-container *ngFor="let nav of fixedNavigation">
          <ng-container *agHasPermission="nav.permission">
            <li>
              <a
                [routerLinkActive]="'bg-white/10'"
                class="
                  text-white/80
                  hover:text-white
                  flex
                  items-center
                  gap-3
                  w-full
                  grow
                  py-2
                  px-3
                  rounded
                  cursor-pointer
                  outline-none
                  whitespace-nowrap
                  select-none
                  focus-visible:ring
                  focus-visible:ring-offset-white
                  focus-visible:ring-offset-2
                  focus-visible:ring-steel-blue-500
                "
                [routerLink]="nav?.route"
                (click)="setMainPageRoute(); showSettings = !showSettings"
              >
                <!-- icon + label -->
                <div class="flex items-center gap-3">
                  <svg-icon [src]="nav.icon"></svg-icon>
                  <span>{{ nav?.label }}</span>
                </div>
              </a>
            </li>
          </ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="logoutExpanded"></ng-container>
      </ul>
    </ng-container>
    <ng-container *ngIf="showSettings">
      <!-- navigate back and line -->
      <ul class="flex flex-col w-full text-white">
        <li>
          <a
            [routerLinkActive]="'bg-white/10'"
            class="
              text-white/80
              hover:text-white
              flex
              items-center
              gap-3
              w-full
              grow
              py-2
              px-3
              rounded
              cursor-pointer
              outline-none
              whitespace-nowrap
              select-none
              focus-visible:ring
              focus-visible:ring-offset-white
              focus-visible:ring-offset-2
              focus-visible:ring-steel-blue-500
            "
            [routerLink]="nav?.route"
            (click)="backToPageNavigation(); showSettings = !showSettings"
          >
            <!-- icon + label -->
            <svg-icon src="assets/icons/general/arrow-left.svg"></svg-icon>
            <span class="truncate"
              >Back to {{ navigationRouteHumanized() }}</span
            >
          </a>
        </li>
        <li>
          <hr class="opacity-25 border-t-[0.5px] bg-slate-300" />
        </li>
      </ul>
      <!-- nav items -->
      <ul
        class="
          flex flex-col
          grow
          overflow-auto
          w-full
          text-white
          p-0.5
          -mt-[18px]
        "
      >
        <ng-container *ngFor="let nav of settingsNavigation">
          <ng-container *agHasPermission="nav.permission">
            <li class="mb-4">
              <a
                class="
                  text-white/80
                  hover:text-white
                  flex
                  items-center
                  gap-3
                  w-full
                  grow
                  py-2
                  px-3
                  rounded
                  cursor-pointer
                  outline-none
                  whitespace-nowrap
                  select-none
                  focus-visible:ring
                  focus-visible:ring-offset-white
                  focus-visible:ring-offset-2
                  focus-visible:ring-steel-blue-500
                "
                [routerLink]="nav?.route"
                [routerLinkActive]="'bg-white/10'"
                (click)="service.activateLink(nav?.label)"
              >
                <!-- icon + label -->
                <svg-icon [src]="nav.icon"></svg-icon>
                <span>{{ nav?.label }}</span>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ul>
      <!-- logout -->
      <ul class="flex flex-col gap-3 w-full text-white mt-auto">
        <ng-container *ngTemplateOutlet="logoutExpanded"></ng-container>
      </ul>
    </ng-container>
  </nav>
</ng-template>

<!-- mobile -->
<ng-template #mobile>
  <div
    class="
      fixed
      top-0
      left-0
      right-0
      z-40
      isolate
      text-white
      flex flex-col
      sm:portrait:hidden
      md:landscape:hidden
    "
    [ngClass]="[service.menuOpen ? 'h-screen' : '']"
  >
    <!-- titlebar -->
    <div class="flex items-center justify-between py-1 px-2 bg-steel-blue-900">
      <a
        class="
          text-xl
          flex
          items-center
          gap-3
          text-white/80
          hover:text-white
          px-2
          py-3
          rounded
          cursor-pointer
          outline-none
          whitespace-nowrap
          select-none
          focus-visible:ring
          focus-visible:ring-offset-white
          focus-visible:ring-offset-2
          focus-visible:ring-steel-blue-500
        "
        [routerLink]="['/']"
        (click)="service.closeMenu()"
      >
        <img src="assets/icons/logo.png" class="w-8 h-8" />
        AgrigateOne
      </a>
      <ng-container *ngIf="!service.menuOpen">
        <button
          class="
            p-1
            hover:bg-white/20
            bg-white/10
            rounded
            cursor-pointer
            outline-none
            whitespace-nowrap
            select-none
            focus-visible:ring
            focus-visible:ring-offset-white
            focus-visible:ring-offset-2
            focus-visible:ring-steel-blue-500
          "
          (click)="service.toggleMenu()"
          [ag1Tooltip]="'Menu'"
          tooltipPosition="bottom"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-8 h-8"
          >
            <path
              fill-rule="evenodd"
              d="M2 6.75A.75.75 0 012.75 6h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 6.75zm0 6.5a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </ng-container>
      <ng-container *ngIf="service.menuOpen">
        <button
          class="
            p-1
            hover:bg-white/20
            bg-white/10
            rounded
            cursor-pointer
            outline-none
            whitespace-nowrap
            select-none
            focus-visible:ring
            focus-visible:ring-offset-white
            focus-visible:ring-offset-2
            focus-visible:ring-steel-blue-500
          "
          (click)="service.toggleMenu()"
          [ag1Tooltip]="Close"
          tooltipPosition="bottom"
        >
          <svg-icon
            *ngIf="service.menuOpen"
            svgClass="w-8 h-8"
            src="/assets/icons/general/close.svg"
          ></svg-icon>
        </button>
      </ng-container>
    </div>

    <!-- nav items -->
    <ng-container *ngIf="service.menuOpen">
      <ng-container *ngIf="!showSettings">
        <ul
          class="
            flex flex-col
            grow
            gap-3
            overflow-auto
            w-full
            text-white
            p-0.5
            bg-steel-blue-800
          "
        >
          <ng-container *ngFor="let nav of mainNavigation">
            <ng-container *ngIf="nav?.items">
              <ng-container *agHasPermissionAny="nav.permissions">
                <li>
                  <!-- toggle trigger -->
                  <button
                    class="
                      font-semibold
                      text-white/80
                      hover:text-white
                      flex
                      items-center
                      gap-3
                      w-full
                      grow
                      px-3
                      py-2
                      rounded
                      relative
                      cursor-pointer
                      outline-none
                      whitespace-nowrap
                      select-none
                      focus-visible:ring
                      focus-visible:ring-offset-white
                      focus-visible:ring-offset-2
                      focus-visible:ring-steel-blue-500
                    "
                    [ngClass]="
                      unsavedNewOrder &&
                      nav?.label === 'Orders' &&
                      !service.expandedList(nav.label)
                        ? 'after:block after:h-2 after:w-2 after:rounded-full after:bg-french-blue-500 after:top-1 after:left-1 after:absolute after:animate-ping'
                        : ''
                    "
                    (click)="service.expandList(nav.label)"
                  >
                    <!-- icon + label -->
                    <div class="flex items-center gap-3 mr-auto">
                      <svg-icon *ngIf="nav?.icon" [src]="nav.icon"></svg-icon>
                      <span>{{ nav?.label }}</span>
                    </div>

                    <!-- toggle icon -->
                    <ng-container *ngIf="service.expandedList(nav?.label)">
                      <svg-icon src="assets/icons/general/chevron-down.svg">
                      </svg-icon>
                    </ng-container>
                    <ng-container *ngIf="!service.expandedList(nav?.label)">
                      <svg-icon src="assets/icons/general/chevron-right.svg">
                      </svg-icon>
                    </ng-container>
                  </button>

                  <!-- nested items -->
                  <ul
                    *ngIf="service.expandedList(nav.label)"
                    class="ml-8 my-2 flex flex-col"
                  >
                    <ng-container *ngFor="let navItem of nav?.items">
                      <ng-container *agHasPermission="navItem.permission">
                        <li>
                          <a
                            class="
                              text-white/80
                              hover:text-white
                              flex
                              items-center
                              gap-3
                              rounded
                              px-3
                              py-2
                              relative
                              before:block
                              before:w-px
                              before:bg-white/20
                              before:-left-2.5
                              before:top-0
                              before:bottom-0
                              before:absolute
                              cursor-pointer
                              outline-none
                              whitespace-nowrap
                              select-none
                              focus-visible:ring
                              focus-visible:ring-offset-white
                              focus-visible:ring-offset-2
                              focus-visible:ring-steel-blue-500
                            "
                            [routerLinkActive]="'bg-white/10'"
                            [ngClass]="[
                              unsavedNewOrder && navItem?.label === 'New Order'
                                ? 'after:block after:h-2 after:w-2 after:rounded-full after:bg-french-blue-500 after:top-1 after:left-1 after:absolute after:animate-ping'
                                : ''
                            ]"
                            [routerLink]="navItem?.route"
                            (click)="
                              service.activateLink(navItem?.label);
                              service.closeMenu()
                            "
                          >
                            <ng-container *ngIf="navItem?.new">
                              <ng-container
                                [ngTemplateOutlet]="newBadge"
                              ></ng-container>
                            </ng-container>

                            <svg-icon
                              *ngIf="navItem?.icon"
                              [src]="navItem.icon"
                            ></svg-icon>

                            <span>{{ navItem?.label }}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!nav.items">
              <ng-container *agHasPermission="nav.permission">
                <li>
                  <a
                    [routerLinkActive]="'bg-white/10'"
                    class="
                      text-white/80
                      hover:text-white
                      flex
                      items-center
                      gap-3
                      w-full
                      grow
                      py-2
                      px-3
                      rounded
                      cursor-pointer
                      outline-none
                      whitespace-nowrap
                      select-none
                      focus-visible:ring
                      focus-visible:ring-offset-white
                      focus-visible:ring-offset-2
                      focus-visible:ring-steel-blue-500
                    "
                    [routerLink]="nav?.route"
                    (click)="
                      service.activateLink(nav?.label); service.closeMenu()
                    "
                  >
                    <!-- icon + label -->
                    <div class="flex items-center gap-3">
                      <svg-icon [src]="nav.icon"></svg-icon>
                      <span>{{ nav?.label }}</span>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>

        <!-- settings/ profile -->
        <ul
          class="
            flex flex-col
            gap-3
            w-full
            text-white
            mt-auto
            bg-steel-blue-800
          "
        >
          <ng-container *ngFor="let nav of fixedNavigation">
            <ng-container *agHasPermission="nav.permission">
              <li>
                <a
                  [routerLinkActive]="'bg-white/10'"
                  class="
                    text-white/80
                    hover:text-white
                    flex
                    items-center
                    gap-3
                    w-full
                    grow
                    py-2
                    px-3
                    rounded
                    cursor-pointer
                    outline-none
                    whitespace-nowrap
                    select-none
                    focus-visible:ring
                    focus-visible:ring-offset-white
                    focus-visible:ring-offset-2
                    focus-visible:ring-steel-blue-500
                  "
                  [routerLink]="nav?.route"
                  (click)="
                    service.activateLink(nav?.label);
                    showSettings = !showSettings
                  "
                >
                  <!-- icon + label -->
                  <div class="flex items-center gap-3">
                    <svg-icon [src]="nav.icon"></svg-icon>
                    <span>{{ nav?.label }}</span>
                  </div>
                </a>
              </li>
            </ng-container>
          </ng-container>

          <ng-container *ngTemplateOutlet="logoutMobile"></ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="showSettings">
        <!-- navigate back and line -->
        <ul
          class="flex flex-col gap-3 w-full text-white p-0.5 bg-steel-blue-800"
        >
          <li>
            <a
              [routerLinkActive]="'bg-white/10'"
              class="
                text-white/80
                hover:text-white
                flex
                items-center
                gap-3
                w-full
                grow
                py-2
                px-3
                rounded
                cursor-pointer
                outline-none
                whitespace-nowrap
                select-none
                focus-visible:ring
                focus-visible:ring-offset-white
                focus-visible:ring-offset-2
                focus-visible:ring-steel-blue-500
              "
              [routerLink]="nav?.route"
              (click)="service.openMenu(); showSettings = !showSettings"
            >
              <!-- icon + label -->
              <svg-icon src="assets/icons/general/arrow-left.svg"></svg-icon>
              <span class="truncate">Back to Menu</span>
            </a>
          </li>
          <li>
            <hr class="m-0 opacity-25 h-px" />
          </li>
        </ul>
        <!-- nav items -->
        <ul
          class="
            flex flex-col
            grow
            gap-3
            overflow-auto
            w-full
            text-white
            p-0.5
            bg-steel-blue-800
          "
        >
          <div class="overflow-auto">
            <ng-container *ngFor="let nav of settingsNavigation">
              <ng-container *agHasPermission="nav.permission">
                <li>
                  <a
                    [routerLinkActive]="'bg-white/10'"
                    class="
                      text-white/80
                      hover:text-white
                      flex
                      items-center
                      gap-3
                      w-full
                      grow
                      py-2
                      px-3
                      rounded
                      cursor-pointer
                      outline-none
                      whitespace-nowrap
                      select-none
                      focus-visible:ring
                      focus-visible:ring-offset-white
                      focus-visible:ring-offset-2
                      focus-visible:ring-steel-blue-500
                    "
                    [routerLink]="nav?.route"
                    (click)="
                      service.activateLink(nav?.label); service.closeMenu()
                    "
                  >
                    <!-- icon + label -->
                    <div class="flex items-center gap-3">
                      <svg-icon [src]="nav.icon"></svg-icon>
                      <span>{{ nav?.label }}</span>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </div>
        </ul>
        <!-- logout -->
        <ul class="flex flex-col gap-3 w-full text-white mt-auto">
          <ng-container *ngTemplateOutlet="logoutMobile"></ng-container>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- notifications -->
<ng-template #notifications>
  <ng-container
    *agHasPermissionEvery="[
      permissionEnum.ViewOrderViewComments,
      permissionEnum.PagesViewOrder
    ]"
  >
    <div class="relative text-white">
      <!-- notifications panel trigger -->
      <button
        ag1Tooltip="
         Notifications
        "
        tooltipPosition="right"
        class="
          text-white/80
          hover:text-white
          rounded
          p-1
          relative
          block
          cursor-pointer
          outline-none
          whitespace-nowrap
          select-none
          focus-visible:ring
          focus-visible:ring-offset-white
          focus-visible:ring-offset-2
          focus-visible:ring-steel-blue-500
          m-0
        "
        (click)="toggleUserFeed()"
        #userFeedNavbarLink
      >
        <svg-icon
          *ngIf="userFeedEdges.length === 0"
          src="assets/icons/general/envelope-open.svg"
        ></svg-icon>
        <svg-icon
          *ngIf="userFeedEdges.length > 0"
          src="assets/icons/general/envelope.svg"
        ></svg-icon>
        <span
          *ngIf="userFeedUnseenItemCount > 0"
          class="
            text-xs
            absolute
            block
            -right-3
            rounded-full
            bg-french-blue-500
            text-white
            min-w-fit
            px-2
            z-[41]
            -top-1.5
          "
        >
          {{ userFeedUnseenItemCount > 99 ? "99+" : userFeedUnseenItemCount }}
        </span>
      </button>

      <!-- notifications panel -->
      <ul
        *ngIf="showUserFeed"
        class="
          ml-8
          my-2
          flex flex-col
          absolute
          left-5
          -top-5
          bg-steel-blue-800
          whitespace-nowrap
          p-3
          rounded
          gap-2
        "
      >
        <!-- close button -->
        <button
          class="
            top-2
            right-2
            absolute
            p-1
            hover:bg-white/20
            bg-white/10
            rounded
            cursor-pointer
            outline-none
            whitespace-nowrap
            select-none
            focus-visible:ring
            focus-visible:ring-offset-white
            focus-visible:ring-offset-2
            focus-visible:ring-steel-blue-500
          "
          (click)="toggleUserFeed(false)"
          [ag1Tooltip]="'Close'"
        >
          <svg-icon src="/assets/icons/general/close.svg"></svg-icon>
        </button>

        <li class="p-1">Notifications</li>

        <ul
          class="max-h-[300px] overflow-y-auto pr-3 flex flex-col gap-2"
          #userFeedContainer
          (scroll)="onUserFeedScroll($event)"
        >
          <ng-container *ngFor="let userFeedItem of userFeedEdges">
            <ng-container
              *ngTemplateOutlet="
                notificationItem;
                context: { $implicit: userFeedItem }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="loadingUserFeed">
            <ng-container
              *ngTemplateOutlet="notificationsLoading"
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="userFeedEdges.length === 0">
            <ng-container *ngTemplateOutlet="notificationsEmpty"></ng-container>
          </ng-container>
        </ul>
      </ul>
    </div>
  </ng-container>
</ng-template>

<ng-template #notificationsEmpty>
  <div class="flex flex-col items-center text-white gap-3 p-5">
    <svg-icon
      class="text-white/50"
      svgClass="h-10 w-10"
      src="assets/icons/general/envelope-open.svg"
    >
    </svg-icon>
    <div class="text-center">
      <h3 class="text-lg">You have no notifications</h3>
      <span class="text-sm">
        When you get a notification they'll show up here.
      </span>
    </div>
  </div>
</ng-template>

<ng-template #notificationsLoading>
  <div
    class="
      grid
      grid-cols-[4ch_auto]
      gap-3
      rounded
      bg-white/10
      p-2
      text-left
      items-center
    "
  >
    <ag1-loader class="scale-50"></ag1-loader>
    <div class="flex flex-col gap-0.5">
      <span>Loading Notifications...</span>
      <span class="text-white/60">Please wait</span>
    </div>
  </div>
</ng-template>

<ng-template #notificationItem let-userFeedItem>
  <button
    class="rounded bg-white/10 p-2 cursor-pointer hover:bg-white/20 text-left"
    (click)="onUserFeedItemClick(userFeedItem.node)"
  >
    <div class="grid grid-cols-[4ch_auto] gap-3">
      <span class="p-3">
        <svg-icon
          class="text-white"
          *ngIf="!userFeedItem.node.read"
          src="assets/icons/general/envelope.svg"
        ></svg-icon>
        <svg-icon
          class="text-white/50"
          *ngIf="userFeedItem.node.read"
          src="assets/icons/general/envelope-open.svg"
        ></svg-icon>
      </span>

      <div class="flex flex-col gap-0.5">
        <span>{{ userFeedItem.node.message }}</span>
        <span class="text-white/60">
          {{ userFeedItem.node.createdAt | timeAgo | async }}
        </span>
      </div>
    </div>
  </button>
</ng-template>

<!-- sidebar -->
<ng-template #sidebarToggle>
  <ag1-button-small
    tooltip="Toggle Sidebar"
    class="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2"
    classOverrides="!rounded-full aspect-square !border-white"
    (clickEvent)="service.toggleSidebar()"
  >
    <svg-icon
      *ngIf="!service.expandedSidebar"
      src="assets/icons/general/chevron-right.svg"
    ></svg-icon>
    <svg-icon
      *ngIf="service.expandedSidebar"
      src="assets/icons/general/chevron-left.svg"
    ></svg-icon>
  </ag1-button-small>
</ng-template>

<!-- brand -->
<ng-template #brand>
  <a
    class="
      text-xl
      flex
      items-center
      gap-3
      text-white/80
      hover:text-white
      px-2
      py-3
      rounded
      cursor-pointer
      outline-none
      whitespace-nowrap
      select-none
      focus-visible:ring
      focus-visible:ring-offset-white
      focus-visible:ring-offset-2
      focus-visible:ring-steel-blue-500
    "
    [routerLink]="['/']"
  >
    <img src="assets/icons/logo.png" class="w-8" />
    <span *ngIf="service.expandedSidebar">AgrigateOne</span>
  </a>
</ng-template>

<!-- new badge -->
<ng-template #newBadge>
  <ag1-badge label="NEW" color="lime"></ag1-badge>
</ng-template>
<!-- logout -->
<ng-template #logoutCollapsed>
  <div
    class="
      flex
      items-center
      justify-between
      bg-steel-blue-900
      px-3
      py-2
      -m-3
      mt-0
    "
  >
    <button
      [ag1Tooltip]="'Logout'"
      tooltipPosition="right"
      class="
        p-2
        rounded
        hover:bg-orange-600
        cursor-pointer
        outline-none
        whitespace-nowrap
        select-none
        focus-visible:ring
        focus-visible:ring-offset-white
        focus-visible:ring-offset-2
        focus-visible:ring-orange-600
      "
      (click)="logout()"
    >
      <svg-icon src="assets/icons/general/logout.svg"></svg-icon>
    </button></div
></ng-template>

<ng-template #logoutExpanded>
  <div
    class="flex items-center justify-between bg-steel-blue-900 px-3 py-2 -m-3"
  >
    <span
      class="
        text-white
        font-semibold
        whitespace-nowrap
        overflow-hidden
        text-ellipsis
      "
    >
      <ng-container *ngIf="!isAgrigateUser">
        {{ currentUser?.firstName | titlecase }}
        {{ currentUser?.lastName | titlecase }}
      </ng-container>
      <ng-container *ngIf="isAgrigateUser">
        {{ currentUser?.company.name | titlecase }}
      </ng-container>
    </span>
    <button
      [ag1Tooltip]="'Logout'"
      class="
        p-2
        rounded
        hover:bg-orange-600
        cursor-pointer
        outline-none
        whitespace-nowrap
        select-none
        focus-visible:ring
        focus-visible:ring-offset-white
        focus-visible:ring-offset-2
        focus-visible:ring-orange-600
      "
      (click)="logout()"
    >
      <svg-icon src="assets/icons/general/logout.svg"></svg-icon>
    </button>
  </div>
</ng-template>

<ng-template #logoutMobile>
  <div class="flex items-center justify-between bg-steel-blue-900 px-3 py-2">
    <span
      class="
        text-white
        font-semibold
        whitespace-nowrap
        overflow-hidden
        text-ellipsis
      "
    >
      <ng-container *ngIf="!isAgrigateUser">
        {{ currentUser.firstName | titlecase }}
        {{ currentUser.lastName | titlecase }}
      </ng-container>
      <ng-container *ngIf="isAgrigateUser">
        {{ currentUser.company.name | titlecase }}
      </ng-container>
    </span>
    <button
      [ag1Tooltip]="'Logout'"
      class="
        p-2
        rounded
        hover:bg-orange-600
        cursor-pointer
        outline-none
        whitespace-nowrap
        select-none
        focus-visible:ring
        focus-visible:ring-offset-white
        focus-visible:ring-offset-2
        focus-visible:ring-orange-600
      "
      (click)="logout()"
    >
      <svg-icon src="assets/icons/general/logout.svg"></svg-icon>
    </button></div
></ng-template>
